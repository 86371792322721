import React, { useState, useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import '../styles/product.scss';
import Shield from '../assets/img/shield2.png' 
/* import ModalVideo from 'react-modal-video' */
import { List, ListItem, ListIcon } from "@chakra-ui/core";


const Product = () => {

    const [isOpen, setOpen] = useState(false)
   
    const openModal = () => {
      setOpen(true)
    }


/*   animate={{y:0 , opacity: 1}}
                  initial={{ y: 72, opacity: 0 }}
                  transition={{
                    duration: 0.5,
                    ease: [0.6, 0.05, -0.01, 0.9],
                  
                }} */

    const BoxVariants = {
        visible: { opacity: 1, y: 0, transition: { duration: 1.5, ease: [0.6, 0.05, -0.01, 0.9] } },
        hidden: { opacity: 0, y: 172 },
        back: { opacity: 0, y: -72 }
        
      };
      const controls = useAnimation();
      const [ref, inView] = useInView();
      useEffect(() => {
        /* inView ? controls.start("visible") : null */
        if (inView) {
          controls.start("visible");
        }
      }, [controls, inView]);
    return (
        
        <div className="product-container bg-gray-100 w-screen h-full py-3">
           {/*  <ModalVideo channel='youtube' start={62} autoplay isOpen={isOpen} videoId="bwzQz6NVxM0" onClose={() => setOpen(false)} /> */}
            <motion.div 
            className="product_items_box py-24"
            ref={ref}
            animate={controls}
            initial="hidden"
            variants={BoxVariants}
            >
            <div className="product_copy_box flex  flex-col justify-center items-center"> 
                <h1 className="product-heading text-center pt-8">Shield v2: the most advanced financing instrument Africa’s early stage companies</h1>
                <p className="product_copy text-center pt-12 text-gray-800 sm:text-xl md:text-xl lg:text-xl xl:text-xl max-w-xl">The Shield- agreement for future equity introduced last year is improved, designed for pre-revenue companies. The ecosystem spoke and we listened we developed the Shield Share Repurchase Agreement and Shield Convertible Revenue Loan for post revenue companies.</p>
                <p className="product_copy text-center pt-12 text-gray-800 sm:text-xl md:text-xl lg:text-xl xl:text-xl max-w-xl">These two instruments are designed with striking a balance between the startup and the investor while rewarding the investor for taking a risk with the startup. They’re designed for companies that believe in their ability to make money or those that are revenue positive in the near future.</p>
                <p className="product2_copy text-center pt-12 text-gray-800 sm:text-xl md:text-xl lg:text-xl xl:text-xl max-w-xl">The Shield Share Repurchase Agreement is a contract between the startup and the investors it is giving equity to raise financing where the company can buy back some of its shares at a later date or on the occurrence of an eligible event.</p>
                <p className="product2_copy text-center pt-12 text-gray-800 sm:text-xl md:text-xl lg:text-xl xl:text-xl max-w-xl">The Shield Convertible Revenue Loan allows for an investor to invest in demonstrated potential for revenue or a post revenue company allowing the company to pay back using gross revenue or conversion options.</p>
                <p className="product2_copy text-center pt-12 text-gray-800 sm:text-xl md:text-xl lg:text-xl xl:text-xl max-w-xl">Special Features;</p>
                <List spacing={3} className="pt-8">
                    <ListItem className="product2_copy text-gray-800 sm:text-xl md:text-xl lg:text-xl xl:text-xl max-w-xl">
                            <ListIcon icon="check-circle" color="green.500" />
                            Cost Effective
                    </ListItem>
                    <ListItem className="product2_copy text-gray-800 sm:text-xl md:text-xl lg:text-xl xl:text-xl max-w-xl">
                            <ListIcon icon="check-circle" color="green.500" />
                            Simple and Fast
                    </ListItem>
                    <ListItem className="product2_copy text-gray-800 sm:text-xl md:text-xl lg:text-xl xl:text-xl max-w-xl">
                            <ListIcon icon="check-circle" color="green.500" />
                            Provides for flip entities  
                    </ListItem>
                    <ListItem className="product2_copy text-gray-800 sm:text-xl md:text-xl lg:text-xl xl:text-xl max-w-xl">
                            <ListIcon icon="check-circle" color="green.500" />
                            Clear Exit Routes
                    </ListItem>
                    <ListItem className="product2_copy text-gray-800 sm:text-xl md:text-xl lg:text-xl xl:text-xl max-w-xl">
                            <ListIcon icon="check-circle" color="green.500" />
                            Discounts for investors
                    </ListItem>
                    <ListItem className="product2_copy text-gray-800 sm:text-xl md:text-xl lg:text-xl xl:text-xl max-w-xl">
                            <ListIcon icon="check-circle" color="green.500" />
                            Simplified Term Sheet to break down all the legalese
                    </ListItem>
                  </List>
                  <p className="product2_copy text-center pt-12 text-gray-800 sm:text-xl md:text-xl lg:text-xl xl:text-xl max-w-xl">Available this December, <a href="#">Click for early access</a></p>
                <div>
                    <button className="px-6 py-3 mt-10 tracking-wider bg-transparent hover:bg-blue-500 text-gray-800 hover:text-white font-semibold border border-gray-800 hover:border-transparent rounded-full" onClick={()=> setOpen(true)}>Watch Keynote</button>
                </div>
            </div>
            <img src={Shield} className="product_img"></img>
            </motion.div>
            {/* <div className="product_ill flex items-center">
                
            </div> */}

        </div>
    )
}

export default Product