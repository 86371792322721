import React, { useState, useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import '../styles/product2.scss';
import Shield from '../assets/img/policy.png';
import { List, ListItem, ListIcon } from "@chakra-ui/core";
/* import ModalVideo from 'react-modal-video' */

const Product2 = () => {
    const [isOpen, setOpen] = useState(false)
   
    const openModal = () => {
      setOpen(true)
    }

    const BoxVariants = {
        visible: { opacity: 1, y: 0, transition: { duration: 1, ease: [0.6, 0.05, -0.01, 0.9] } },
        hidden: { opacity: 0, y: 172 },
        back: { opacity: 0, y: -72 },
        
      };

      const controls = useAnimation();
      const [ref, inView] = useInView();
      useEffect(() => {
        /* inView ? controls.start("visible") : null */
        if (inView) {
          controls.start("visible");
        }
      }, [controls, inView]);

    return (
        <div className="product2-container bg-gray-100 w-screen h-full py-3">
            {/* <ModalVideo channel='youtube' start={62} autoplay isOpen={isOpen} videoId="bwzQz6NVxM0" onClose={() => setOpen(false)} /> */}
            <motion.div 
                className="product2_items_box py-24"
                ref={ref}
                animate={controls}
                initial="hidden"
                variants={BoxVariants}
                >
            <img src={Shield} className="product2_img"></img>
            <div className="product2_copy_box flex  flex-col justify-center items-center"> 
                <h1 className="product2-heading text-center">Launchbase</h1>
                <p className="product2_copy text-center pt-12 text-gray-800 sm:text-xl md:text-xl lg:text-xl xl:text-xl max-w-xl">Evolved out of last year’s document repository platform and advocate-client collaboration platforms CLE Online and Access, the two have been merged, improved with new features to create the LaunchBase platform.</p>
                <List spacing={3} className="pt-10">
                    <ListItem className="product2_copy text-gray-800 sm:text-xl md:text-xl lg:text-xl xl:text-xl max-w-xl">
                            <ListIcon icon="check-circle" color="green.500" />
                            Automatically generate and store key legal documents that are accessible anywhere at anytime.
                    </ListItem>
                    <ListItem className="product2_copy text-gray-800 sm:text-xl md:text-xl lg:text-xl xl:text-xl max-w-xl">
                            <ListIcon icon="check-circle" color="green.500" />
                            Watch explainer videos that simplify complicated legal terms.
                    </ListItem>
                    <ListItem className="product2_copy text-gray-800 sm:text-xl md:text-xl lg:text-xl xl:text-xl max-w-xl">
                            <ListIcon icon="check-circle" color="green.500" />
                            Minimized physical attorney engagements on "simple" matters
                    </ListItem>
                    </List>
                    <p className="product2_copy text-center pt-12 text-gray-800 sm:text-xl md:text-xl lg:text-xl xl:text-xl max-w-xl"> Launchbase beta Available February 30, 2021</p>
                <div>
                    <button className="px-6 py-3 mt-10 tracking-wider bg-transparent hover:bg-blue-500 text-gray-800 hover:text-white font-semibold border border-gray-800 hover:border-transparent rounded-full" onClick={() => setOpen(true)/* navigate('/') */ }>Watch Keynote</button>
                </div>
            </div>
            
            </motion.div>
            {/* <div className="product_ill flex items-center">
                
            </div> */}
        </div>
    )
}

export default Product2