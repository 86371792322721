import React, { useState, useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import '../styles/product3.scss';
import Shield from '../assets/img/shield4.png' 
/* import ModalVideo from 'react-modal-video' */
import { List, ListItem, ListIcon } from "@chakra-ui/core";


const Product3 = () => {
    const [isOpen, setOpen] = useState(false)
   
    const openModal = () => {
      setOpen(true)
    }

    const BoxVariants = {
        visible: { opacity: 1, y: 0, transition: { duration: 1, ease: [0.6, 0.05, -0.01, 0.9] } },
        hidden: { opacity: 0, y: 172 },
        
      };

      const controls = useAnimation();
      const [ref, inView] = useInView();
      useEffect(() => {
        /* inView ? controls.start("visible") : null */
        if (inView) {
          controls.start("visible");
        }
      }, [controls, inView]);


    return (
        <div className="product3-container bg-gray-100 w-screen h-full py-3">
            {/* <ModalVideo channel='youtube' start={62} autoplay isOpen={isOpen} videoId="bwzQz6NVxM0" onClose={() => setOpen(false)} /> */}
            <motion.div 
                className="product3_items_box py-24"
                ref={ref}
                animate={controls}
                initial="hidden"
                variants={BoxVariants}
            >
            <div className="product3_copy_box flex  flex-col justify-center items-center"> 
                <h1 className="product3-heading text-center">Next.Legal SandBox</h1>
                <p className="product3_copy text-center pt-12 text-gray-800 sm:text-xl md:text-xl lg:text-xl xl:text-xl max-w-xl">It is a pioneer regulatory sandbox that allows companies who intend to scale or an opportunity to test and deliver technologies to new markets.It allows startups to experiment and explore different legal systems, financing and exit strategies.</p>
                <List spacing={3} className="pt-8">
                    <ListItem className="product2_copy text-gray-800 sm:text-xl md:text-xl lg:text-xl xl:text-xl max-w-xl">
                            <ListIcon icon="check-circle" color="green.500" />
                            Allows the different ecosystem players (starpus and investors) to directly scale expeditiously, breaking common barriers such as appropriate financing and exits.
                    </ListItem>
                    <ListItem className="product2_copy text-gray-800 sm:text-xl md:text-xl lg:text-xl xl:text-xl max-w-xl">
                            <ListIcon icon="check-circle" color="green.500" />
                            Intended to stimulate startups, high growth companies and investors to unlock the value in new markets by gaining access to streamlined regulatory support, financing agreements, capital market instruments and structuring models.
                    </ListItem>
                  </List>
                <div>
                    <button className="px-6 py-3 mt-10 tracking-wider bg-transparent hover:bg-blue-500 text-gray-800 hover:text-white font-semibold border border-gray-800 hover:border-transparent rounded-full" onClick={() => setOpen(true) }>Watch Keynote</button>
                </div>
            </div>
            <img src={Shield} className="product3_img"></img>
            </motion.div>
            {/* <div className="product_ill flex items-center">
                
            </div> */}

        </div>
    )
}

export default Product3