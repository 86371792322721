import React, { useState } from "react"
import IdentityModal from "react-netlify-identity-widget"
import CookieConsent from "react-cookie-consent";
import {
  useNetlifyIdentity,
} from "react-netlify-identity-widget"

/* import { Router } from "@reach/router"
import PrivateRoute from "../components/privateRoute";
import Summit from "../pages/summit/index"
import Agenda from "../pages/summit/agenda"
import Sponsors from "../pages/summit/sponsors"
import Lobby from "../pages/summit/lobby"
import Session from "../pages/summit/session"
import Founder from "../pages/summit/founder"
import Investor from "../pages/summit/investor" */




import { navigate } from "gatsby"
import { Helmet } from 'react-helmet';
import LazyHero from 'react-lazy-hero';
import Scroll from '../components/scroll';

import '../styles/newHome.scss'
import '../styles/homeStyles.scss'
import '../styles/modal-video.scss'


import { motion, AnimatePresence } from "framer-motion";

import '../css/icofont.min.css'
import '../css/animate.min.css'
import Layout from "../components/layout";
import {Footer3} from "../components/footer3";
/* import { Speakers } from "../components/speakers";
import { Testimony } from "../components/testimony"; */
import Product from "../components/product";
import Product2 from "../components/product2";
import Product3 from "../components/product3";


const IndexPage = props => {
  
/*   const initState = {
    days: '',
    hours: '',
    minutes: '',
    seconds: '',
    isOpen: false
};

  const [state, setState] = useState(initState)
  const [isOpen, setOpen] = useState(false)
  const formatValue = (value) => value.toFixed(2);

  const openModal = () => {
    setOpen(true)
  }
 */

/*   const makeTimer = () => {
    let endTime = new Date("October 23, 2020 09:00:00 PDT");			
    let endTimeParse = (Date.parse(endTime)) / 1000;
    let now = new Date();
    let nowParse = (Date.parse(now) / 1000);
    let timeLeft = endTimeParse - nowParse;
    let days = Math.floor(timeLeft / 86400); 
    let hours = Math.floor((timeLeft - (days * 86400)) / 3600);
    let minutes = Math.floor((timeLeft - (days * 86400) - (hours * 3600 )) / 60);
    let seconds = Math.floor((timeLeft - (days * 86400) - (hours * 3600) - (minutes * 60)));
    if (hours < "10") { hours = "0" + hours; }
    if (minutes < "10") { minutes = "0" + minutes; }
    if (seconds < "10") { seconds = "0" + seconds; }
    setState({
        days, hours, minutes, seconds
    });
} */

/* useEffect(() => {
 const myInterval = setInterval(() => { 
    makeTimer();
}, 1000);
  return () => {
    clearInterval(myInterval);
  }
}, []) */


const [showDialog, setShowDialog] = useState(false)
const identity = useNetlifyIdentity()
const isLoggedIn = identity && identity.isLoggedIn

  return <>
             <Helmet>
                <title>Next.Legal</title>
                <meta name="description" content="Next.legal is a showcase of innovations in legal tech and finance for the African ecosystem." />
                <meta name="keywords" content="legal tech, startup, law" />
                <meta
                  name="robots"
                  content="max-snippet:-1, max-image-preview:large, max-video-preview:-1"
                />
                <link rel="canonical" href="http://nextlegal.io/" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Next.Legal " />
                <meta property="og:description" content="Next.legal is a showcase of innovations in legal tech and finance for the African ecosystem." />
                <meta property="og:url" content="https://next-legal.io" />
                <meta property="og:site_name" content="Next.Legal" />
                <meta property="og:image" content="../assets/img/icon-256x256.png" />
                <meta property="og:image:secure_url" content="https://nextlegal.io" />
                <meta property="og:image:width" content="1280" />
                <meta property="og:image:height" content="720" />
                <meta name="twitter:card" content="../assets/img/icon-256x256.png" />
                <meta name="twitter:description" content="Next.legal is a showcase of innovations in legal tech and finance for the African ecosystem." />
                <meta name="twitter:title" content="Next.Legal" />
                <meta name="twitter:image" content="../assets/img/icon-256x256.png" />
             </Helmet>
        {/*      <Router>
                <PrivateRoute path="/summit" component={Summit} > </PrivateRoute>
               
                <PrivateRoute path="/summit/lobby" component={Lobby} > </PrivateRoute>
                <PrivateRoute path="/summit/sponsors" component={Sponsors} > </PrivateRoute>
                <PrivateRoute path="/summit/session" component={Session} > </PrivateRoute>
                <PrivateRoute path="/summit/agenda" component={Agenda} > </PrivateRoute>
                <PrivateRoute path="/summit/founder" component={Founder} > </PrivateRoute>
                <PrivateRoute path="/summit/investor" component={Investor} > </PrivateRoute>
     
            </Router> */}
             <Layout>
             <CookieConsent
             buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
             
             >This website uses cookies to enhance the user experience.</CookieConsent>
             <IdentityModal
        showDialog={showDialog}
        onCloseDialog={() => setShowDialog(false)}
        onLogin={() => {
          setShowDialog(false);
          navigate("/app/summit")
        
        }}
        onSignup={() => {
          setShowDialog(false);
          navigate("/app/summit")
        
        }}
        onLogout={() => {
          setShowDialog(false);
          navigate("/")
        
        }}
        
      />
             {/* <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="CnDwh58N3EM" onClose={() => setOpen(false)} /> */}
             <AnimatePresence>
            <LazyHero 
            imageSrc="https://res.cloudinary.com/mfanolab-design/image/upload/fl_progressive:steep/v1602142313/next-legal/crowd_wnuokl.jpg"
            minHeight="85vh"
            color="#041328"
            opacity={0.7}
            parallaxOffset={50}
            isCentered={true}
            className="hero"
            children={
              <>
              <motion.div
              animate={{ y: 0, opacity: 1 }}
              initial={{ y: 100, opacity: 0 }}
              transition={{
                duration: 1,
                ease: [0.6, 0.05, -0.01, 0.9],
              }}
              /* sm:text-lg md:text-xl lg:text-2xl xl:text-3xl */
              className="hero_Content flex flex-col items-center "> 
              <h1 className="copy_theme_h  text-gray-100 sm:text-4xl md:text-4xl lg:text-3xl xl:text-6xl text-4xl">Next Legal {/* in earlystage financing.{/* */} <span className="summy"> Summit</span> 2021<span className="summy"></span></h1>
                 <p className="copy_theme text-gray-100 sm:text-xl md:text-xl lg:text-xl xl:text-xl">The NextLegal Summit celebrates African Startup tech-ecosystem. Now its third year, the event has been designed to showcase emerging financial structures, perspectives on regulatory and advances in legal technology.</p>
                 <button className="px-6 py-3 mt-10  tracking-wider bg-transparent hover:bg-blue-500 text-gray-100 hover:text-white font-semibold border border-gray-100 hover:border-transparent rounded-full" onClick={() => {/* !isLoggedIn ? setShowDialog(true) : */ navigate("/app/summit")} }>Join Event</button>
                          <div className="scroltainer mt-20">
                            <section id="section05" class="demo">
                                    <a href="#"><span></span></a>
                            </section>
                          </div>
                                   {/*   <ul className="mt-16 text-lg  text-gray-100 flex flex-row items-center">
                                        <li><i className="icofont-compass"></i> Online Event</li>
                                        <li className="ml-4"><i className="icofont-calendar"></i> 23 October, 2020</li>
                                    </ul> */}
            {/*   <div className="register_promo flex flex-row items-center mt-8">
                                  <button 
                                  onClick={() => { navigate('/register')} }
                                  className="px-6 py-2 font-bold tracking-wider  text-gray-800 rounded-full events"
                                  >Watch the event</button>
                                    <div className="button-box">
                                              <div 
                                                  onClick={e => {e.preventDefault(); openModal()}}
                                                  className="video-btn popup-youtube cursor-pointer"
                                              >
                                                  <i className="icofont-ui-play"></i> Watch Promo Video
                                              </div>
                                    </div>
                            </div> */}
                </motion.div>
              </>
            }
            >
            </LazyHero>
            </AnimatePresence>
            
        {/* <Speakers/> */}
        {/* <LazyHero 
            imageSrc="https://res.cloudinary.com/mfanolab-design/image/upload/c_scale,fl_progressive:steep,h_1258/v1601710809/next-legal/ray_fkhqna.jpg"
            minHeight="40vh"
            color="#041328"
            opacity={0.7}
            parallaxOffset={50}
            isCentered={true}
            className="hero"
            children={
              <>
              <div className="hero_Content flex flex-col items-center "> 
                    <div className="event-countdown">
                                          <div className="timer flex">
                                                <div className="t-group flex flex-col"> 
                                          
                                                    <div className="t-up text-gray-100 text-6xl">00<div className="t-down text-gray-100 text-xl">Days</div></div>
    
                                                </div>

                                                <div className="t-group flex flex-col mx-4"> 
                                                    <div className="t-up text-gray-100 text-6xl">00<div className="t-down text-gray-100 text-xl ">Hours</div></div>
                                                </div>

                                                <div className="t-group flex flex-col mr-4"> 
                                                    <div className="t-up text-gray-100 text-6xl">00<div className="t-down text-gray-100 text-xl">Minutes</div></div>
                                                </div>

                                                <div className="t-group flex flex-col"> 
                                                    <div className="t-up text-gray-100 text-6xl">00<div className="t-down text-gray-100 text-xl">Seconds</div></div>
                                                </div>
                                          </div>
                                          <button className="px-6 py-3 mt-10  tracking-wider bg-transparent hover:bg-blue-500 text-gray-100 hover:text-white font-semibold border border-gray-100 hover:border-transparent rounded-full" onClick={() => { navigate('/register')} }>Watch the event</button>
                    </div>


                </div>
              </>
            }
            >
            </LazyHero> */}
            <Product/>
            <Product2/>
            <Product3/>
            <Scroll showBelow={250} />
            <Footer3/>
            </Layout>
        </>
}

export default IndexPage
